import React from 'react'
import { TableCell, TableHead, TableRow } from '@material-ui/core'

export default function BusinessesTableHead({ headerGroups, isApproveAllowed, handleSelectAll }) {
  return (
    <TableHead>
      {headerGroups.map((headerGroup) => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {isApproveAllowed && (
            <TableCell>
              <input type="checkbox" onChange={handleSelectAll} />
            </TableCell>
          )}
          {headerGroup.headers.map((column) => (
            <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
          ))}
          <TableCell />
        </TableRow>
      ))}
    </TableHead>
  )
}
