import React from 'react'
import { TableBody, TableCell, TableRow, Button } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'

import STATUS from '../../constants/businessStatus'
import useStyles from './styles'

export default function BusinessesTableBody({
  page,
  prepareRow,
  isApproveAllowed,
  selectedRowIds,
  handleSelectRow,
  handleGoToDetails,
  loading,
}) {
  const classes = useStyles()

  return (
    <TableBody>
      {page.map((row) => {
        prepareRow(row)
        const className = { hover: classes.rowHover }
        let disableEditButton = false

        if (row.original.status === STATUS.VERIFIED) {
          className.root = classes.rowVerified
          // disableEditButton = !isApproveAllowed
        } else if (row.original.status === STATUS.PENDING) {
          className.root = classes.rowPending
        } else if (row.original.status === STATUS.PROCEEDING) {
          className.root = classes.rowInProgress
        } else if (row.original.status === STATUS.TO_BE_DELETED) {
          className.root = classes.rowToBeDeleted
        }

        return (
          <TableRow hover classes={className} {...row.getRowProps()}>
            {isApproveAllowed && (
              <TableCell>
                <input
                  type="checkbox"
                  checked={selectedRowIds.includes(row.original.id)}
                  onChange={() => handleSelectRow(row.original)}
                />
              </TableCell>
            )}
            {row.cells.map((cell) => {
              if (cell?.column?.id === 'profileType' && cell?.value === 'promoted') {
                return (
                  <TableCell {...cell.getCellProps()} component="th" scope="row">
                    <b>{cell?.value}</b>
                  </TableCell>
                )
              }

              if (cell?.column?.id === 'website' && cell.value) {
                const websiteToDisplay = cell.value
                  .replace(/(^\w+:|^)\/\//, '')
                  .substring(0, 15)
                  .concat('...')

                return (
                  <TableCell {...cell.getCellProps()} component="th" scope="row">
                    {websiteToDisplay}
                  </TableCell>
                )
              }

              if (cell?.column?.id === 'logo.url' && cell.value) {
                return (
                  <TableCell {...cell.getCellProps()} component="th" scope="row">
                    <img src={cell.value} height="40" />
                  </TableCell>
                )
              }

              if (cell?.column?.id === 'status' && cell.value) {
                let nameToDisplay = ''

                if (cell.value === STATUS.DEFAULT) {
                  nameToDisplay = 'Raw Queue'
                } else if (cell.value === STATUS.PROCEEDING) {
                  nameToDisplay = 'Saved Drafts'
                } else if (cell.value === STATUS.PENDING) {
                  nameToDisplay = 'Pending Approval'
                } else if (cell.value === STATUS.VERIFIED) {
                  nameToDisplay = 'Approved And Live'
                } else if (row.original.status === STATUS.TO_BE_DELETED) {
                  nameToDisplay = 'To Be Deleted'
                }

                return (
                  <TableCell {...cell.getCellProps()} component="th" scope="row">
                    {nameToDisplay}
                  </TableCell>
                )
              }
              return (
                <TableCell {...cell.getCellProps()} component="th" scope="row">
                  {cell.render('Cell')}
                </TableCell>
              )
            })}
            <TableCell>
              <Button
                disabled={disableEditButton}
                variant="contained"
                onClick={(event) => handleGoToDetails(event, row.original)}
              >
                <SettingsIcon />
              </Button>
            </TableCell>
          </TableRow>
        )
      })}
      {loading && (
        // Use our custom loading state to show a loading indicator
        <TableRow>
          <TableCell colSpan="10000" component="th" scope="row">
            Loading...
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )
}
