import { createStyles, makeStyles } from '@material-ui/core/styles'

const drawerWidth = 240

export default makeStyles(() =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    listItem: {
      margin: '16px 0',
    },
  }),
)
