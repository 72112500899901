import React from 'react'
import { Drawer, Toolbar, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'

import {
  Business as IconBusiness,
  Notes as IconArticle,
  Assessment as IconAnalytics,
  ShoppingCart as IconAmazon,
  Celebration as IconEvents,
  AdsClick as IconAds,
} from '@mui/icons-material'

import { useRouter } from 'next/router'

import useStyles from './SideBar.styles'

const SideBar = () => {
  const classes = useStyles()
  const router = useRouter()

  const handleBusinessesClick = () => {
    router.push('/')
  }

  const handleArticlesClick = () => {
    router.push('/articles')
  }

  const handleAnalyticsClick = () => {
    router.push('/analytics')
  }

  const handleAmazonClick = () => {
    router.push('/amazon')
  }

  const handleEventsClick = () => {
    router.push('/events')
  }

  const handleAdsClick = () => {
    router.push('/campaigns')
  }

  const ListItems = [
    {
      name: 'Businesses',
      onClick: handleBusinessesClick,
      Icon: IconBusiness,
    },
    {
      name: 'Articles',
      onClick: handleArticlesClick,
      Icon: IconArticle,
    },
    {
      name: 'Analytics',
      onClick: handleAnalyticsClick,
      Icon: IconAnalytics,
    },
    {
      name: 'Amazon',
      onClick: handleAmazonClick,
      Icon: IconAmazon,
    },
    {
      name: 'Events',
      onClick: handleEventsClick,
      Icon: IconEvents,
    },
    {
      name: 'Ads',
      onClick: handleAdsClick,
      Icon: IconAds,
    },
  ]

  const NavList = () => (
    <List>
      {ListItems.map((item) => {
        const { name, Icon, onClick } = item
        return (
          <ListItem button onClick={onClick} className={classes.listItem} key={name}>
            {Icon && (
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
            )}
            <ListItemText>{name}</ListItemText>
          </ListItem>
        )
      })}
    </List>
  )

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <NavList />
      </div>
    </Drawer>
  )
}

export default SideBar
