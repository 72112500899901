import { makeStyles, createStyles } from '@material-ui/core/styles'

export default makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    main: {
      flexGrow: 1,
      paddingTop: theme.spacing(8),
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  })
)
