import { gql } from '@apollo/client'

export const VERIFY_BUSINESSES = gql`
  mutation verifyBusiness($ids: [ID]!, $editorId: ID!) {
    verifyBusiness(ids: $ids, editorId: $editorId)
  }
`

export const UPDATE_BUSINESS = gql`
  mutation updateBusiness(
    $id: ID!
    $createdBy: ID!
    $title: String!
    $profileType: String!
    $createdAt: Date!
    $updatedAt: Date!
    $description: String
    $rating: Float
    $phone: String
    $website: String
    $email: String
    $streetAddress: String
    $city: String
    $state: String
    $postalCode: String
    $status: String
    $tags: [Int]
    $editorId: ID!
    $socialNetworks: [socialNetworkInput]
    $lon: Float
    $lat: Float
    $verified: Boolean
    $note: String
    $hasMultipleLocation: Boolean
    $experience: [Int]
    $speciality: [Int]
    $amenity: [Int]
    $trustVerification: [Int]
    $discount: [Int]
    $alias: String
    $titleOverride: String
    $keywordsOverride: String
    $descriptionOverride: String
    $ageGroup: AgeGroupInput
    $pricegroup: Int
    $programs: [ProgramInput]
    $seoReady: Boolean
    $seoReadyDateTime: Date
    $promoted: PromotedInput
    $video: VideoInput
  ) {
    updateBusiness(
      id: $id
      createdBy: $createdBy
      title: $title
      profileType: $profileType
      createdAt: $createdAt
      updatedAt: $updatedAt
      description: $description
      rating: $rating
      phone: $phone
      website: $website
      email: $email
      streetAddress: $streetAddress
      city: $city
      state: $state
      postalCode: $postalCode
      status: $status
      tags: $tags
      editorId: $editorId
      socialNetworks: $socialNetworks
      lon: $lon
      lat: $lat
      verified: $verified
      note: $note
      hasMultipleLocation: $hasMultipleLocation
      experience: $experience
      speciality: $speciality
      amenity: $amenity
      trustVerification: $trustVerification
      discount: $discount
      alias: $alias
      titleOverride: $titleOverride
      keywordsOverride: $keywordsOverride
      descriptionOverride: $descriptionOverride
      ageGroup: $ageGroup
      pricegroup: $pricegroup
      programs: $programs
      seoReady: $seoReady
      seoReadyDateTime: $seoReadyDateTime
      promoted: $promoted
      video: $video
    ) {
      id
      locationId
      createdBy
      title
      profileType
      createdAt
      updatedAt
      description
      rating
      phone
      website
      streetAddress
      postalCode
      city
      state
      status
      tags
      socialNetworks {
        name
        url
      }
      lon
      lat
      verified
      note
      hasMultipleLocation
      seoReady
      promoted {
        from
        to
        active
      }
    }
  }
`

export const CREATE_BUSINESS = gql`
  mutation createBusiness(
    $createdBy: ID!
    $title: String!
    $profileType: String!
    $createdAt: Date!
    $updatedAt: Date!
    $description: String
    $rating: Float
    $phone: String
    $website: String
    $email: String
    $streetAddress: String
    $city: String
    $state: String
    $postalCode: String
    $status: String
    $tags: [Int]
    $editorId: ID!
    $socialNetworks: [socialNetworkInput]
    $lon: Float
    $lat: Float
    $verified: Boolean
    $hasMultipleLocation: Boolean
    $experience: [Int]
    $speciality: [Int]
    $amenity: [Int]
    $trustVerification: [Int]
    $discount: [Int]
    $alias: String
    $titleOverride: String
    $keywordsOverride: String
    $descriptionOverride: String
    $ageGroup: AgeGroupInput
    $pricegroup: Int
    $programs: [ProgramInput]
    $seoReady: Boolean
    $promoted: PromotedInput
    $video: VideoInput
  ) {
    createBusiness(
      createdBy: $createdBy
      title: $title
      profileType: $profileType
      createdAt: $createdAt
      updatedAt: $updatedAt
      description: $description
      rating: $rating
      phone: $phone
      website: $website
      email: $email
      streetAddress: $streetAddress
      city: $city
      state: $state
      postalCode: $postalCode
      status: $status
      tags: $tags
      editorId: $editorId
      socialNetworks: $socialNetworks
      lon: $lon
      lat: $lat
      verified: $verified
      hasMultipleLocation: $hasMultipleLocation
      experience: $experience
      speciality: $speciality
      amenity: $amenity
      trustVerification: $trustVerification
      discount: $discount
      alias: $alias
      titleOverride: $titleOverride
      keywordsOverride: $keywordsOverride
      descriptionOverride: $descriptionOverride
      ageGroup: $ageGroup
      pricegroup: $pricegroup
      programs: $programs
      seoReady: $seoReady
      promoted: $promoted
      video: $video
    ) {
      id
      locationId
      createdBy
      title
      profileType
      description
      rating
      phone
      website
      streetAddress
      postalCode
      city
      state
      status
      tags
      socialNetworks {
        name
        url
      }
      lon
      lat
      verified
      hasMultipleLocation
      seoReady
      promoted {
        from
        to
        active
      }
    }
  }
`

export const UPLOAD_LOGO = gql`
  mutation uploadLogo($id: ID!, $file: Upload!) {
    uploadLogo(id: $id, file: $file)
  }
`

export const DELETE_LOGO = gql`
  mutation deleteLogo($id: ID!) {
    deleteLogo(id: $id)
  }
`

export const UPLOAD_PHOTOS = gql`
  mutation uploadPhotos($id: ID!, $files: [FileUploadInput]!, $editorId: ID!) {
    uploadPhotos(id: $id, files: $files, editorId: $editorId) {
      id
      width
      height
      uri
    }
  }
`

export const DELETE_PHOTOS = gql`
  mutation deletePhotos($id: ID!, $files: [FileDeleteInput], $editorId: ID!) {
    deletePhotos(id: $id, files: $files, editorId: $editorId)
  }
`

export const UPLOAD_USER_PHOTOS = gql`
  mutation uploadUserPhotos($id: ID, $files: [FileUploadInput]!, $editorId: ID!) {
    uploadUserPhotos(id: $id, files: $files, editorId: $editorId) {
      id
      width
      height
      uri
    }
  }
`

export const DELETE_USER_PHOTOS = gql`
  mutation deleteUserPhotos($id: ID!, $files: [FileDeleteInput], $editorId: ID!) {
    deleteUserPhotos(id: $id, files: $files, editorId: $editorId)
  }
`

export const UPLOAD_PROGRAM_IMAGE = gql`
  mutation uploadProgramImage($id: ID!, $files: [String]!) {
    uploadProgramImage(id: $id, files: $files)
  }
`

export const UPDATE_REVIEWS = gql`
  mutation AddReviews($businessId: ID, $reviews: [ReviewUpdateInput]) {
    addReviews(businessId: $businessId, reviews: $reviews)
  }
`

export const DELETE_REVIEWS = gql`
  mutation deleteReviews($ids: [ID]) {
    deleteReviews(ids: $ids)
  }
`

export const UPDATE_FAQS = gql`
  mutation UpdateFaqs($businessId: ID, $faqs: [UpdateFaqInput]) {
    updateFaqs(businessId: $businessId, faqs: $faqs)
  }
`

export const DELETE_FAQS = gql`
  mutation deleteFaqs($ids: [ID]) {
    deleteFaqs(ids: $ids)
  }
`
