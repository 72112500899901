export default function setRouteParams(router, value, paramName, pathName) {
  const routerParams = { ...router.query }

  if (!value && routerParams[paramName]) {
    delete routerParams[paramName]
  } else {
    if (value) {
      routerParams[paramName] = value
    } else {
      return null
    }
  }

  const params = {
    pathname: pathName ? pathName : router.pathname,
    query: {
      ...routerParams,
    },
  }
  router.push(params)
}
