import { makeStyles, createStyles } from '@material-ui/core/styles'

export default makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: '100%',
        minHeight: '500px',
      },
      tableWrapper: {
        position: 'relative',
      },
      rowVerified: {
        backgroundColor: '#B5FAC3FF',
      },
      rowInProgress: {
        backgroundColor: '#72CBF1FF',
      },
      rowPending: {
        backgroundColor: '#FCC8B2',
      },
      rowToBeDeleted: {
        backgroundColor: '#f88080',
      },
      rowHover: {
        cursor: 'pointer',
      },
      filters: {
        display: 'flex',
        alignItems: 'start',
        flexWrap: 'wrap',
      },
      filtersContainer: {
        margin: theme.spacing(2),
      },
      formControlWrapper: {
        marginLeft: 'auto',
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        '&:first-child': {
          marginLeft: 0,
        },
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      loader: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: 10,
      },
    }),
  { index: 1 }
)
