// NavBar.js или любой другой файл компонента
import React from 'react'
import { useSession, signOut } from 'next-auth/react'

import Typography from '@mui/material/Typography'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import {
  StyledAppBar,
  StyledToolbar,
  StyledTitle,
  StyledUser,
  StyledAvatar,
  StyledButton,
} from './styled.js'

const NavBar = () => {
  const { data: session, status } = useSession()
  const loading = status === 'loading'

  return (
    <StyledAppBar position="fixed">
      <StyledToolbar>
        <StyledTitle variant="h6">
          <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            Editors Tool
          </a>
        </StyledTitle>
        {!loading && session && (
          <StyledUser>
            <StyledAvatar
              src={`${process.env.NEXT_PUBLIC_REACT_APP_PHOTO_STORAGE}/${session?.user?.image}`}
            >
              {session?.user?.name}
            </StyledAvatar>
            <Typography variant="body1">
              <span>{session?.user?.name}</span>
            </Typography>
            <StyledButton
              variant="contained"
              color="inherit"
              startIcon={<ExitToAppIcon />}
              onClick={signOut}
            >
              Sign out
            </StyledButton>
          </StyledUser>
        )}
      </StyledToolbar>
    </StyledAppBar>
  )
}

export default NavBar
