import { Grid, Paper } from '@material-ui/core'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import NavBar from '../NavBar/NavBar'
import SideBar from '../SideBar/SideBar'

import useStyles from '../../styles/home.styles'

export default function Layout({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <NavBar />
      <SideBar />
      <main className={classes.main}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>{children}</Paper>
          </Grid>
        </Grid>
        <ToastContainer position="bottom-center" autoClose={2000} hideProgressBar={false} />
      </main>
    </div>
  )
}
