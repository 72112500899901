import React, { useState, useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTable, usePagination } from 'react-table'
import { Table, TableContainer, Paper, CircularProgress } from '@material-ui/core'
import { toast } from 'react-toastify'

import { ALLOWED_TO_APPROVE } from '../../constants/editors'
import { GET_BUSINESSES, REFRESH_MAT_VIEWS_MUTATION } from '../../schema/queries/business'
import { VERIFY_BUSINESSES } from '../../schema/mutations/business'

import setRouteParams from '../../helpers/setRouteParams'

import BusinessesTableFilters from './BusinessesTableFilters'
import BusinessesTableHead from './BusinessesTableHead'
import BusinessesTableBody from './BusinessesTableBody'
import BusinessesTablePagination from './BusinessesTablePagination'

import useStyles from './styles'

const columns = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'title', Header: 'Title' },
  { accessor: 'status', Header: 'Status' },
  { accessor: 'profileType', Header: 'Type' },
  { accessor: 'streetAddress', Header: 'Street Address' },
  { accessor: 'city', Header: 'City' },
  { accessor: 'state', Header: 'State' },
  { accessor: 'postalCode', Header: 'ZipCode' },
  { accessor: 'phone', Header: 'Phone' },
  { accessor: 'website', Header: 'Website' },
  { accessor: 'logo.url', Header: 'Logo' },
]

// const DEFAULT_STATE = 'MA'
const DEFAULT_STATE = 'all'

export default function BusinessesTable() {
  const classes = useStyles()
  const router = useRouter()
  const [getBusinesses, { called, loading: isBusinessLoading, data, fetchMore, error }] =
    useLazyQuery(GET_BUSINESSES)
  const [refreshViews, { loading: isViewsAreRefreshing }] = useMutation(REFRESH_MAT_VIEWS_MUTATION)

  const [verifyBusiness] = useMutation(VERIFY_BUSINESSES)

  const [pageCount, setPageCount] = useState(0)
  const [filter, setFilter] = useState({}) // state: DEFAULT_STATE, withWebsite: true
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const [loading, setLoading] = useState(false)

  const { data: session } = useSession()
  const isApproveAllowed = ALLOWED_TO_APPROVE.includes(session.user.role)

  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: data?.allBusinesses?.data ?? [],
      initialState: { pageIndex: Number(router.query.page) || 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount,
    },
    usePagination,
  )

  useEffect(() => {
    setLoading(isBusinessLoading)
    if (error) {
      setLoading(false)
    }
  }, [isBusinessLoading, error])

  useEffect(() => {
    let initialFilterParams = {
      // from router
      id: router.query['fb_id'],
      title: router.query['fb_title'],
      city: router.query['fb_city'],
      postalCode: router.query['fb_postalCode'],
      status:
        !isApproveAllowed && router.query['fb_status'] === '5' ? null : router.query['fb_status'], // is not allowed to set to be deleted filter for editors
      state: router.query['fb_state'] || DEFAULT_STATE,
      withWebsite: router.query['fb_withWebsite'] === 'true' || false,
      type: router.query['fb_type'],
    }

    setFilter({
      ...initialFilterParams,
    })

    getBusinesses({
      variables: {
        page: pageIndex,
        perPage: pageSize,
        filter: initialFilterParams,
      },
    })
  }, [router.isReady])

  useEffect(() => {
    if (data?.allBusinesses?.count) {
      setPageCount(data?.allBusinesses?.count)
    }
  }, [data?.allBusinesses?.count])

  const handleGoToDetails = useCallback((event, row) => {
    event.stopPropagation()
    router.push(`/business/${row.id}`)
  }, [])

  const handleGoToCreate = useCallback((event, row) => {
    event.stopPropagation()
    router.push('/business/create')
  }, [])

  const handleFilterChange = useCallback(
    async (value) => {
      const filterName = Object.keys(value)[0]
      const filterValue = value[filterName]

      gotoPage(0)
      setFilter({ ...filter, ...value })

      setLoading(true)
      const fetchMoreData = await fetchMore({
        variables: {
          page: pageIndex,
          perPage: pageSize,
          filter: { ...filter, ...value },
        },
      })

      setLoading(fetchMoreData?.loading)

      setRouteParams(router, filterValue?.toString(), `fb_${filterName}`)
    },
    [pageIndex, pageSize, filter, router, fetchMore],
  )

  const handlePageChange = useCallback(
    async (page) => {
      setRouteParams(router, page === 0 ? null : page, 'page')

      setLoading(true)

      const fetchMoreData = await fetchMore({
        variables: {
          page,
          perPage: pageSize,
          filter,
        },
      })

      setLoading(fetchMoreData?.loading)
    },
    [pageSize, filter, router, fetchMore],
  )

  const handleRowsPerPageChange = useCallback(
    async (pageSize) => {
      setRouteParams(router, null, 'page')

      setPageSize(pageSize)
      setLoading(true)

      const fetchMoreData = await fetchMore({
        variables: {
          page: 0,
          perPage: pageSize,
          filter,
        },
      })

      setLoading(fetchMoreData?.loading)

      setLoading(false)
    },
    [pageSize, filter, router, fetchMore],
  )

  const handleSelectAll = useCallback(
    (event) => {
      const businessesIds = data?.allBusinesses.data?.map((item) => item.id)
      if (!event.target.checked) {
        setSelectedRowIds([])
      } else {
        if (selectedRowIds?.length < businessesIds.length || !selectedRowIds.length) {
          setSelectedRowIds(businessesIds)
        } else {
          setSelectedRowIds([])
        }
      }
    },
    [selectedRowIds?.length, data?.allBusinesses?.data?.length],
  )

  const handleSelectRow = useCallback(
    (row) => {
      if (selectedRowIds.length && selectedRowIds.includes(row.id)) {
        setSelectedRowIds(selectedRowIds.filter((item) => item !== row.id))
      } else {
        setSelectedRowIds([].concat(selectedRowIds, row.id))
      }
    },
    [selectedRowIds.length],
  )

  const handleApproveClick = useCallback(async () => {
    await verifyBusiness({
      variables: { ids: selectedRowIds, editorId: session.user.id },
    })

    setLoading(true)

    const fetchMoreData = await fetchMore({
      variables: {
        page: pageIndex,
        perPage: pageSize,
        filter,
      },
    })

    setLoading(fetchMoreData?.loading)

    if (fetchMoreData) {
      setSelectedRowIds([])
      toast.success('Successfuly updated')
    }
  }, [selectedRowIds, session.user.id, pageIndex, pageSize, filter, fetchMore])

  const handleRefreshClick = useCallback(async () => {
    setLoading(true)

    await refreshViews()
    setLoading(false)
  }, [])

  // todo: fix apollo in  memory cache for _allBusinessesMeta
  // todo: fix apollo in  memory cache for allBusinesses
  return (
    <Paper>
      <BusinessesTableFilters
        defaultState={filter.state}
        withWebsite={filter.withWebsite}
        multipleLocation={filter.multipleLocation}
        onFilterChange={handleFilterChange}
        onApproveClick={handleApproveClick}
        onRefreshClick={handleRefreshClick}
        onGoToCreate={handleGoToCreate}
        isApproveEnabled={Boolean(selectedRowIds.length)}
        isApproveAllowed={isApproveAllowed}
      />
      <TableContainer className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table aria-label="simple table">
            <BusinessesTableHead
              headerGroups={headerGroups}
              isApproveAllowed={isApproveAllowed}
              handleSelectAll={handleSelectAll}
              loading={loading}
            />
            <BusinessesTableBody
              page={page}
              prepareRow={prepareRow}
              isApproveAllowed={isApproveAllowed}
              selectedRowIds={selectedRowIds}
              handleSelectRow={handleSelectRow}
              handleGoToDetails={handleGoToDetails}
              loading={loading}
            />
            <BusinessesTablePagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              nextPage={nextPage}
              previousPage={previousPage}
              pageIndex={pageIndex}
              pageSize={pageSize}
              pageCount={!Boolean(data?.allBusinesses?.data?.length) ? 0 : pageCount}
              colSpan={10000}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Table>
        </div>
        {loading && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
      </TableContainer>
    </Paper>
  )
}
