import { gql } from '@apollo/client'

export const GET_BUSINESSES = gql`
  query Businesses($page: Int, $perPage: Int, $filter: BusinessFilter) {
    allBusinesses(page: $page, perPage: $perPage, filter: $filter) {
      data {
        id
        locationId
        title
        profileType
        createdAt
        updatedAt
        phone
        website
        streetAddress
        postalCode
        city
        state
        status
        logo {
          url
        }
      }
      count
    }
  }
`

// was part of GET_BUSINESSES
//     _allBusinessesMeta(filter: $filter) {
//       count
//     }

export const GET_BUSINESS = gql`
  query Business($id: ID!) {
    Business(id: $id) {
      id
      locationId
      createdBy
      title
      profileType
      createdAt
      updatedAt
      description
      rating
      phone
      website
      email
      status
      city
      state
      streetAddress
      postalCode
      lon
      lat
      logo {
        url
      }
      tags
      photos {
        id
        uri
        width
        height
        status
      }
      userPhotos {
        id
        uri
        width
        height
        status
      }
      socialNetworks {
        name
        url
      }
      verified
      note
      hasMultipleLocation
      experience {
        id
        name
      }
      speciality {
        id
        name
      }
      amenity {
        id
        name
      }
      trustVerification {
        id
        name
      }
      alias
      titleOverride
      keywordsOverride
      descriptionOverride
      ageGroup {
        type
        from
        to
        name
      }
      pricegroup {
        id
        name
        markCount
      }
      programs {
        id
        name
        description
        image
        url
      }
      seoReady
      seoReadyDateTime
      discount {
        id
        name
      }
      promoted {
        from
        to
        active
      }
      faqs {
        id
        question
        answer
      }
      reviews {
        id
        date
        text
        rating {
          overall
          staffQuality
          facilityRating
          childApproved
          recommendToOthers
        }
        user {
          id
          name
          image
        }
      }
      video {
        type
        name
        url
        video_id
        thumbnail
      }
    }
  }
`

export const GET_STATES_AND_STATUS = gql`
  query states {
    states {
      code
      name
    }
    businessStatus {
      id
      name
    }
  }
`

export const REFRESH_MAT_VIEWS_MUTATION = gql`
  mutation refreshMaterializedViews {
    refreshMaterializedViews
  }
`
