import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import debounce from 'lodash.debounce'
import {
  Typography,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  InputLabel,
  TextField,
  Button,
  Checkbox,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined'
import { useRouter } from 'next/router'

import { GET_STATES_AND_STATUS } from '../../schema/queries/business'
import STATUS from '../../constants/businessStatus'

import useStyles from './styles'

const DELAY = 300

export default function BusinessesTableFilters({
  defaultState,
  withWebsite,
  multipleLocation,
  onFilterChange,
  isApproveEnabled,
  isApproveAllowed,
  onApproveClick,
  // onRefreshClick,
  onGoToCreate,
}) {
  const classes = useStyles()
  const router = useRouter()

  const [state, setState] = useState('')
  const [website, setWebsite] = useState(false)
  const [hasMultipleLocation, setHasMultipleLocation] = useState(false)
  const [status, setStatus] = useState()
  const [businessType, setBusinessType] = useState()
  const [getStatesAndStatus, { data }] = useLazyQuery(GET_STATES_AND_STATUS)

  useEffect(() => {
    getStatesAndStatus()
  }, [])

  useEffect(() => {
    if (defaultState !== undefined) {
      setState(defaultState)
    }
  }, [defaultState])

  useEffect(() => {
    if (withWebsite !== undefined) {
      setWebsite(withWebsite)
    }
  }, [withWebsite])

  useEffect(() => {
    if (multipleLocation !== undefined) {
      setHasMultipleLocation(multipleLocation)
    }
  }, [multipleLocation])

  const handleTitleChange = debounce((e) => {
    onFilterChange({ title: e.target.value })
  }, DELAY)

  const handleCityChange = debounce((e) => {
    onFilterChange({ city: e.target.value })
  }, DELAY)

  const handleIdChange = debounce((e) => {
    const businessIdValue = e.target.value.replace(/[^0-9]/g, '')
    onFilterChange({ id: businessIdValue })
  }, DELAY)

  const handleZipCodeChange = debounce((e) => {
    onFilterChange({ postalCode: e.target.value })
  }, DELAY)

  const handleStateChange = (e) => {
    setState(e.target.value)
    onFilterChange({ state: e.target.value })
  }

  const handleStatusChange = (e) => {
    const value = Number(e.target.value)
    setStatus(value)
    onFilterChange({ status: value === -1 ? undefined : value })
  }

  const handleTypeChange = (e) => {
    const value = e.target.value
    setBusinessType(value)
    onFilterChange({ type: value === -1 ? undefined : value })
  }

  const handleWithWebsiteChange = (e) => {
    setWebsite(e.target.checked)
    onFilterChange({ withWebsite: e.target.checked })
  }

  const handleHasMultipleLocationChange = (e) => {
    setHasMultipleLocation(e.target.checked)
    onFilterChange({ multipleLocation: e.target.checked })
  }

  return (
    <header className={classes.filtersContainer}>
      <Typography gutterBottom variant="h6" component="h2" align="left">
        Filters
      </Typography>
      <div className={classes.filters}>
        <FormControl className={classes.formControl}>
          <TextField
            id="standard-search-id"
            label="Id"
            type="search"
            onChange={handleIdChange}
            defaultValue={router.query['fb_id']}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="standard-search-title"
            label="Title"
            type="search"
            onChange={handleTitleChange}
            defaultValue={router.query['fb_title']}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="state-label">State</InputLabel>
          <Select labelId="state-label" id="state" value={state} onChange={handleStateChange}>
            <MenuItem key="all" value="all">
              All
            </MenuItem>
            {data?.states?.map(({ code }) => (
              <MenuItem key={code} value={code}>
                {code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="standard-search-city"
            label="City"
            type="search"
            onChange={handleCityChange}
            defaultValue={router.query['fb_city']}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="zipcode"
            label="Zipcode"
            type="number"
            onChange={handleZipCodeChange}
            defaultValue={router.query['fb_postalCode']}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status"
            value={router.query['fb_status'] || status}
            onChange={handleStatusChange}
          >
            <MenuItem key="all" value="-1">
              All
            </MenuItem>
            {data?.businessStatus?.map(({ name, id }) => {
              let nameToDisplay = ''

              if (name === STATUS.DEFAULT) {
                nameToDisplay = 'Raw Queue'
              } else if (name === STATUS.PROCEEDING) {
                nameToDisplay = 'Saved Drafts'
              } else if (name === STATUS.PENDING) {
                nameToDisplay = 'Pending Approval'
              } else if (name === STATUS.VERIFIED) {
                nameToDisplay = 'Approved And Live'
              } else if (isApproveAllowed && name === STATUS.TO_BE_DELETED) {
                // is not allowed to set to be deleted filter for editors
                nameToDisplay = 'To Be Deleted'
              }

              return (
                <MenuItem key={id} value={id} style={{ textTransform: 'capitalize' }}>
                  {nameToDisplay}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            labelId="type-label"
            id="type"
            value={router.query['fb_type'] || businessType}
            onChange={handleTypeChange}
          >
            <MenuItem key="all" value={-1}>
              All
            </MenuItem>

            <MenuItem key={'basic'} value={'basic'} style={{ textTransform: 'capitalize' }}>
              {'basic'}
            </MenuItem>
            <MenuItem key={'promoted'} value={'promoted'} style={{ textTransform: 'capitalize' }}>
              {'promoted'}
            </MenuItem>
          </Select>
        </FormControl>
        <div className={classes.formControlWrapper}>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={website}
                  onChange={handleWithWebsiteChange}
                  name="withWebsite"
                  color="primary"
                />
              }
              label="With Website"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasMultipleLocation}
                  onChange={handleHasMultipleLocationChange}
                  name="multipleLocation"
                  color="primary"
                />
              }
              label="Multiple Location"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Button variant="contained" onClick={onGoToCreate}>
              <AddIcon />
              &nbsp;Add Business
            </Button>
          </FormControl>
          {isApproveAllowed && (
            <FormControl className={classes.formControl}>
              <Button variant="contained" disabled={!isApproveEnabled} onClick={onApproveClick}>
                <ThumbUpAltOutlinedIcon />
                &nbsp;Approve
              </Button>
            </FormControl>
          )}
          {/*{isApproveAllowed && (*/}
          {/*  <FormControl className={classes.formControl}>*/}
          {/*    <Button variant="outlined" color="secondary" onClick={onRefreshClick}>*/}
          {/*      Refresh Views*/}
          {/*    </Button>*/}
          {/*  </FormControl>*/}
          {/*)}*/}
        </div>
      </div>
    </header>
  )
}
