import React from 'react'
import { TableFooter, TablePagination, TableRow } from '@material-ui/core'

const PAGE_SIZE = 10
const PER_PAGE_OPTIONS = [PAGE_SIZE, 20, 50, 100, 250, 500, 1000]

export default function BusinessesTablePagination({
  canPreviousPage,
  canNextPage,
  nextPage,
  previousPage,
  pageIndex,
  pageSize,
  pageCount,
  colSpan,
  onPageChange,
  onRowsPerPageChange,
}) {
  const handleChangePage = (event, newPage) => {
    onPageChange(newPage)
    if (newPage < pageIndex && canPreviousPage) {
      previousPage()
    }

    if (newPage > pageIndex && canNextPage) {
      nextPage()
    }
  }

  const handleChangeRowsPerPage = (event) => {
    onRowsPerPageChange(parseInt(event.target.value, 10))
  }

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={PER_PAGE_OPTIONS}
          colSpan={colSpan}
          count={pageCount}
          rowsPerPage={pageSize}
          page={pageIndex}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableRow>
    </TableFooter>
  )
}
