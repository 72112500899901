import { signIn, useSession } from 'next-auth/react'

import Layout from '../components/Layout/Layout'
import BusinessesTable from '../components/BusinessesTable/BusinessesTable'

export default function Page() {
  const { data: session, status } = useSession()
  const loading = status === 'loading'

  if (!loading && !session) {
    signIn()
  }

  if (!session) {
    return 'Loading ...'
  }

  return (
    <Layout>
      <BusinessesTable />
    </Layout>
  )
}
